.imageColumn {
    padding-bottom: 8px;
    padding-left: 8px;
}

.label {
    font-weight: bold;
    margin-right: 8px;
    text-align: right;
}

.moveDown_4 {
    position: relative;
    top: 4px;
}

.moveDown_8 {
    position: relative;
    top: 8px;
}

.questionMarkIcon {
    color: #27b039;
    cursor: pointer;
    margin-left: 8px;
    position: relative;
    top: 8px;
}

.questionMarkIcon2 {
    color: #27b039;
    cursor: pointer;
    margin-right: 16px;
    position: relative;
    top: 16px;
}

.linkHelperPrompt {
    color: #961175;
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 100px;
    margin-top: 75px;
    width: 500px;
}