.borderSpacing_0 {
    border-spacing: 0;
}

.displayNone {
    display: none;
}

.fontSize_0_9em {
    font-size: 0.9em;
}

.marginBottom_8 {
    margin-bottom: 8px;
}

.marginBottom_16 {
    margin-bottom: 16px;
}

.marginBottom_48 {
    margin-bottom: 48px;
}

.marginTop_0 {
    margin-top: 0;
}

.marginLeft_24 {
    margin-left: 24px;
}

.marginTop_20 {
    margin-top: 20px;
}

.minHeight_500 {
    min-height: 500px;
}

.minWidth_300 {
    min-width: 300px;
}

.spanLink {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.textAlignCenter {
    text-align: center;
}

.textAlignRight {
    text-align: right;
}

.whiteSpaceNoWrap {
    white-space: nowrap;
}

.width_350 {
    width: 350px;
}